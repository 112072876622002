<template>
  <div class="page-not-found">
    <div class="content">
      <div class="exclamation">
        <div class="rectangle"></div>
        <div class="circle"></div>
      </div>
      <div class="logo-hey"></div>
      <h2 class="title text-subtitle">{{ error404.title }}</h2>
      <h1 class="code text-title">404</h1>
      <h2 class="subtitle text-subtitle">{{ error404.subtitle }}</h2>
      <router-link to="/" class="button">{{ error404.buttonText }}</router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      error404: {
        title: this.$t('login.error-404.title'),
        subtitle: this.$t('login.error-404.subtitle'),
        buttonText: this.$t('login.error-404.button-text'),
      },
    };
  },
  mounted() {
    this.collapse = window.screen.width < 1024;
  },
};
</script>

<style lang="scss" scoped>
  @import '~styles/views/_page-not-found.scss';
</style>
